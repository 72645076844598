import styled from 'styled-components'
import tw from 'twin.macro'

const FormattedHtmlContent = styled.div`
  ul {
    margin-left: 3.5em;
    ${tw`list-square list-outside`}
  }
  li::marker {
    ${tw`text-red`}
  }
  p,
  li {
    ${tw`text-sm md:text-base`}
  }
  a {
    ${tw`underline text-red`}
  }
`

export default FormattedHtmlContent
