import React, { FC } from 'react'
import CaseStudyLinkButton from 'src/components/sections/caseStudies/CaseStudyLinkButton'
import FormattedHtmlContent from 'src/components/ui/FormattedHtmlContent'
import CaseStudiesTeaser from 'src/components/sections/teaser/CaseStudiesTeaser'

interface ICaseStudiesListItemProps {
  subtitle: string
  firstPartOfTitle: string
  secondPartOfTitle: string
  contentHeader: string
  content: string
  ctaLink: string
  ctaText: string
  image: {
    url: string
    alt: string
  }
  isReversed: boolean
  isImageSnapped: boolean
}

const CaseStudiesListItem: FC<ICaseStudiesListItemProps> = ({
  subtitle,
  firstPartOfTitle,
  secondPartOfTitle,
  contentHeader,
  content,
  ctaLink,
  ctaText,
  image,
  isReversed,
  isImageSnapped,
}) => {
  const getButton = (className: string): JSX.Element => (
    <CaseStudyLinkButton
      ctaLink={ctaLink}
      ctaText={ctaText}
      className={className}
    />
  )

  return (
    <CaseStudiesTeaser
      translationContent={{
        subtitle,
        firstPartOfTitle,
        secondPartOfTitle,
        image,
        sectionName: contentHeader,
      }}
      isReversed={isReversed}
      isImageSnapped={isImageSnapped}
      mobileFooterChildren={getButton('mt-12 md:hidden text-center')}
    >
      <h3 className="mt-8 md:mt-12 text-2xl text-red font-palanquinDark font-semibold">
        {contentHeader}
      </h3>
      <FormattedHtmlContent
        dangerouslySetInnerHTML={{ __html: content }}
        className={`mt-4 text-sm md:text-base font-semibold ${
          isReversed ? 'md:ml-10' : 'md:mr-10'
        }`}
      />
      {getButton('mt-9 hidden md:block')}
    </CaseStudiesTeaser>
  )
}

export default CaseStudiesListItem
