import { gsap, ScrollTrigger } from 'gsap/all'
import { useEffect, useRef, MutableRefObject } from 'react'
import { useIsLargeScreen } from 'src/helpers/layout'
import { createShowFromBottomAnimation } from 'src/helpers/animations'

gsap.registerPlugin(ScrollTrigger)

export const useBasicTeaserAnimations = (
  titleRef: MutableRefObject<HTMLDivElement>,
  largeTitleRef: MutableRefObject<HTMLDivElement>,
  contentRef: MutableRefObject<HTMLDivElement>,
  imgRef: MutableRefObject<HTMLDivElement>,
  footerButtonRef: MutableRefObject<HTMLDivElement>
) => {
  const timeline = useRef(gsap.timeline())
  const isLarge = useIsLargeScreen()

  const animations = () => {
    createShowFromBottomAnimation(timeline.current, titleRef.current)
    createShowFromBottomAnimation(timeline.current, largeTitleRef.current)
    createShowFromBottomAnimation(timeline.current, contentRef.current)
    createShowFromBottomAnimation(timeline.current, imgRef.current)
    createShowFromBottomAnimation(timeline.current, footerButtonRef.current)
  }

  useEffect(() => {
    if (isLarge === null) {
      return
    }

    animations()
  }, [isLarge])
}

export default {}
