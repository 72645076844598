// i18next-extract-mark-ns-start case-studies

import { graphql } from 'gatsby'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import * as React from 'react'
import { FC } from 'react'
import CaseStudiesListItem from 'src/components/sections/caseStudies/CaseStudiesListItem'
import ContactSection from 'src/components/sections/ContactSection'
import Button from 'src/components/ui/Button'
import { directusFileUrl } from 'src/helpers/directus'
import Layout from '../components/layout'
import Hero from '../components/sections/hero'

interface ICaseStudyButtonProps {
  ctaLink: string
  ctaText: string
  className?: string
}

const CaseStudyLinkButton: FC<ICaseStudyButtonProps> = ({
  ctaLink,
  ctaText,
  className,
}) => (
  <div className={className}>
    <Link to={ctaLink}>
      <Button>{ctaText}</Button>
    </Link>
  </div>
)

CaseStudyLinkButton.defaultProps = {
  className: '',
}

const IndexPage = ({ data }) => {
  const { t } = useTranslation('case-studies')

  const { cases } = data.directus

  const metas = []
  return (
    <Layout title={t('Case studies')} metas={metas}>
      <Hero
        translationContent={{
          sectionName: t('sectionName'),
          subtitle: t('subtitle'),
          firstPartOfTitle: t('firstPartOfTitle'),
          secondPartOfTitle: t('secondPartOfTitle'),
          content: t('Overview of case studies'),
          ctaLink: t('ctaLink'),
          ctaText: t('ctaText'),
        }}
        showBadges
      />

      {cases.map((caseStudy, index) => {
        const {
          subtitle,
          firstPartOfTitle,
          secondPartOfTitle,
          contentHeader,
          excerpt,
          ctaText,
        } = caseStudy.t[0]
        const isReversed = index % 2 === 1
        return (
          <CaseStudiesListItem
            key={caseStudy.id}
            subtitle={subtitle}
            firstPartOfTitle={firstPartOfTitle}
            secondPartOfTitle={secondPartOfTitle}
            contentHeader={contentHeader}
            content={excerpt}
            ctaLink={`/case-studies${caseStudy.slug}`}
            ctaText={ctaText}
            image={{
              url: directusFileUrl(caseStudy.desktopImage.id),
              alt: `${firstPartOfTitle} ${secondPartOfTitle}`,
            }}
            isReversed={isReversed}
            isImageSnapped={!isReversed}
          />
        )
      })}
      <ContactSection />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    directus {
      cases: case_study(sort: "sort") {
        id
        slug
        platforms
        t: translations(
          filter: { languages_id: { code: { _eq: $language } } }
        ) {
          contentHeader: content_header
          excerpt
          subtitle
          firstPartOfTitle: title_part_1
          secondPartOfTitle: title_part_2
          ctaText: link_text
          languages_id {
            code
          }
        }
        desktopImage: desktop_image {
          id
        }
      }
    }
  }
`
